<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('report_bonus.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('report_bonus.head_list') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span style="display: flex">
              <div
                style="width: 98px; height: 38px; text-agent: center; background: #e5e5e5; border-radius: 8px; color: #737076; display: flex; justify-content: center; align-items: center;">
                {{ $t('partner') }} </div>
              <!-- <v-select :items="partner" v-model="valPartner" @change="onChangePartner($event)" item-text="label" style="max-width: 210px;"/> -->
              <v-autocomplete outlined dense v-model="valPartner" :items="partner" :search-input.sync="search"
                :loading="isLoadingPartner"></v-autocomplete>
            </span>
            <span style="display: flex; margin-left: 5px;">
              <date-picker-input ref="startDate" :date="dateFrom" :label="$t('report_bonus.date_from')"
                @setValue="dateFrom = $event" style="max-width: 250px;" />
              <v-dialog ref="dialog" v-model="modal2" :return-value.sync="time2" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="time2" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs"
                    v-on="on" class="time-start"></v-text-field>
                </template>
                <v-time-picker v-if="modal2" v-model="time2" format="24hr">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(time2)">
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </span>
            <span style="display: flex; margin-left: 5px;">
              <date-picker-input ref="endDate" :date="dateTo" :label="$t('report_bonus.date_to')"
                @setValue="dateTo = $event" style="max-width: 250px;" />
              <v-dialog ref="dialog2" v-model="modal3" :return-value.sync="time3" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="time3" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs"
                    v-on="on" class="time-start"></v-text-field>
                </template>
                <v-time-picker v-if="modal3" v-model="time3" format="24hr">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal3 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog2.save(time3)">
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </span>
            <span style="display: flex; margin-left: 5px;">
              <div
                style="width: 98px; height: 38px; text-agent: center; background: #e5e5e5; border-radius: 8px; color: #737076; display: flex; justify-content: center; align-items: center;">
                {{ $t('report_bonus.header5') }} </div>
              <v-select :items="roles" item-text="label" v-model="valType" outlined dense style="max-width: 110px;" />
            </span>
            <v-btn color="primary" @click="searchBonus" class="" style="margin-left: 5px;"> {{ $t('search') }} </v-btn>
            <v-btn color="warning" @click="preloadExportCsv" class="" style="margin-left: 5px;"> {{ $t('report_bonus.export_excel') }} </v-btn>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5"> {{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" @change="onChangeSearch($event)" :date="searchData" outlined
              dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <!-- <span>สรุปยอดโบนัสแนะนำเพื่อน</span> -->
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :loading="loading" :page.sync="pagination.currentPage" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :items="members">
            <template slot="body.append">
              <tr>
                <th class="text-center">{{ $t('report_bonus.summary') }}</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th class="text-center">{{ totalRows.toLocaleString() }}</th>
              </tr>
              <tr>
                <th class="text-center">{{ $t('report_bonus.summary_all') }}</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th class="text-center">{{ totalSummary | currency }}</th>
              </tr>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              {{ item.amount | currency }}
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import store from '@/store';
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },
  data() {
    return {
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      searchData: null,
      time: null,
      time2: "00:00",
      time3: "23:59",
      menu2: false,
      modal2: false,
      menu3: false,
      modal3: false,
      valPartner: "พันธมิตร",
      valType: "ทั้งหมด",
      headers: [
        {
          text: this.$t('report_bonus.header1'),
          value: 'date',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('report_bonus.header2'),
          value: 'member',
          width: '90px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('report_bonus.header3'),
          value: 'amount',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('report_bonus.header4'),
          value: 'bonus',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('report_bonus.header5'),
          value: 'type',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('report_bonus.header6'),
          value: 'detail',
          width: '120px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('report_bonus.header7'),
          value: 'member',
          width: '90px',
          align: 'center',
          sortable: false,
        },
      ],
      members: [],
      partner: [this.$t('report_bonus.status1')],
      roles: [this.$t('report_bonus.status1'), this.$t('report_bonus.status2'), this.$t('report_bonus.status3'), this.$t('report_bonus.status4'), this.$t('report_bonus.status5')],
      elpartner: [this.$t('report_bonus.status1'), this.$t('partner') + '1', this.$t('partner') + '2', this.$t('partner') + '3'],
      loading: false,
      totalSummary: 0.00,
      totalRows: 0,
      pagination: {
        currentPage: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      ketType: {
        turnover: this.$t('report_bonus.turnover'),
        turnwinloss: this.$t('report_bonus.turnwinlose'),
      },
      total: 0,
      dummy: true,
      newTag: '',
      entries: [],
      queryTerm: '',
      isLoadingPartner: false,
      json: [
        { name: 'Dady', age: '21' },
        { name: 'Jonh', age: '25' },
        { name: 'James', age: '17' },
      ],
    }
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // await this.getBonus(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData, this.valPartner, this.valType)
        if (cont && !this.dummy) await this.getBonus(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData, this.valPartner, this.valType)
        if (cont && this.dummy) await this.getBonus(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData, this.valPartner, this.valType)
      },
      deep: true,
    },
  },
  computed: {
    search: {
      get() {
        return this.queryTerm
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput
          this.loadEntries(searchInput)
        }
      }
    }
  },
  async created() {
    // this.getPartner(1, 100, null)
    this.addLogPage()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'รายงานโบนัส',
            url: window.location.href,
            detail: 'รายงานการรับโบนัส',
            admin: userSystem.name ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async searchBonus() {
      this.dummy = false
      await this.getBonus(
        1,
        this.pagination.rowsPerPage,
        this.$refs.startDate.value,
        this.$refs.endDate.value,
        this.searchData,
        this.valPartner,
        this.valType
      )
    },
    async getBonus(page, row, time_from, time_to, search, partner, type) {
      this.loading = true
      this.members = []
      // let total = await this.$store.dispatch('getBonusTotal')
      // this.total = Number(total.sum_total).toFixed(2)
      let typeConv = null
      switch (type) {
        case 'เชิญเพื่อน':
          typeConv = 'affiliate'
          break;
        case 'วงล้อ':
          typeConv = 'wheel'
          break;
        case 'คืนยอดเสีย':
          typeConv = 'cashback'
          break;
        case 'โบนัสฝากถอนเงิน':
          typeConv = 'deposit'
          break;
        default:
          break;
      }
      let valArr = this.valPartner.split(':')
      let valPartnerConv = null
      if (valArr.length > 1) {
        valPartnerConv = valArr[2].replace(" ", "");
      }
      // console.log(valArr);
      let rs = await this.$store.dispatch('getBonus', {
        page: page,
        rows: row,
        ...(!search ? { page: page } : {}),
        ...(!search ? { rows: row } : {}),
        ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
        ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
        ...(search ? { search: search } : {}),
        ...(partner && partner !== 'ทั้งหมด' ? { partner: valPartnerConv } : {}),
        ...(type && type !== 'ทั้งหมด' ? { type: typeConv } : {}),
      })
      this.pagination.totalItems = rs.count
      this.totalSummary = rs.total_amount ? Number(rs.total_amount).toFixed(2) : ''
      this.totalRows = rs.count ? Number(rs.count) : ''
      rs.data.forEach(element => {
        this.members.push({
          id: element.id,
          member: element.member.phone,
          amount: element.amount,
          bonus: element.bonus,
          type: element.type,
          detail: element.detail,
          date: element.created_at ? moment(element.created_at).format('YY-MM-DD HH:mm') : '',
        })
      })
      this.loading = false
    },
    async getPartner(page, row, search) {
      this.isLoadingPartner = true
      let rs = await this.$store.dispatch('getPartner', {
        page: page,
        rows: row,
        ...(search && search !== 'ทั้งหมด' ? { search: search } : {}),
      })
      rs.data.forEach(element => {
        this.partner.push(`${element.phone ? element.phone : 'null'} : ${element.name ? element.name : 'null'} id: ${element.id}`)
      })

      this.isLoadingPartner = false
    },
    async searchKeyword(value) {
      this.dummy = true;
      this.searchData = value
      await this.getBonus(1, this.pagination.itemsPerPage, null, null, value, this.valPartner, this.valType)
      this.pagination.currentPage = 1
    },

    async preloadExportCsv() {
      //////// get logExportData ////////
      let logExportData;
      const userSystem = store.getters.getuserInfo
      await fetch("https://jsonip.com/").then(res => res.json().then(el => (
        logExportData = {
          ip: el.ip,
          exportPage: 'รายงานโบนัส - รายงานการรับโบนัส',
          url: window.location.href,
          detail: 'รายงานการรับโบนัส',
          admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
        }
      )))

      this.$swal({
        title: `Secure code | รายงาน${logExportData.exportPage}`,
        input: 'password',
        // inputAttributes: {
        //   inputmode: 'numeric',
        //   pattern: '[0-9]*',
        //   maxlength: 6,
        // },
        inputValidator: value => {
          if (!value) {
            return 'โปรดกรอก Secure code'
          }
          // if (value && value.length !== 6) {
          //   return 'โปรดกรอกให้ครบ 6 หลัก'
          // }
        },
        inputPlaceholder: 'ใส่รหัสที่นี่...',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ออก',
      }).then(async result => {
        if (result.isConfirmed) {
          const pin = result.value
          let enterSecureData = {
            ...logExportData,
            keyCheck: pin
          }
          this.loading = true
          let resEnterSecureData = await this.$store.dispatch('checkLogEnterSecure', enterSecureData)
          if(resEnterSecureData && resEnterSecureData.secure){
            this.exportCsv()
          } else {
            this.$swal.fire('ไม่สามารถ download ได้ เนื่องจากรหัส PIN ไม่ถูกต้อง', '', 'error')
          }
          this.loading = false
        }
      })
    },
    async exportCsv(){
      let typeConv = null
      switch (this.valType) {
        case 'เชิญเพื่อน':
          typeConv = 'affiliat'
          break;
        case 'วงล้อ':
          typeConv = 'wheel'
          break;
        case 'คืนยอดเสีย':
          typeConv = 'cashback'
          break;
        case 'โบนัสฝากถอนเงิน':
          typeConv = 'deposit'
          break;
        default:
          break;
      }

      this.$swal.fire({
        html: this.$t("processing"),
        timerProgressBar: true,
        didOpen: () => { this.$swal.showLoading() }
      })

      let fileName = `${this.$t('report_bonus.file_name')}_${moment(new Date()).format('YYYY-MM-DD_HH-mm')}`
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const wscols = [
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 40 },
        { wch: 20 },
      ];
      let statementlist = []

      let rs = await this.$store.dispatch('getBonus', {
        page: 1,
        rows: 1000000,
        ...(this.$refs.startDate.value ? { time_from: this.formatDateYYYY(this.$refs.startDate.value) } : {}),
        ...(this.$refs.endDate.value ? { time_to: this.formatDateYYYY(this.$refs.endDate.value) } : {}),
        ...(this.searchData ? { search: this.searchData } : {}),
        ...(this.valPartner && this.valPartner !== 'ทั้งหมด' ? { partner: this.valPartner } : {}),
        ...(this.valType && this.valType !== 'ทั้งหมด' ? { type: typeConv } : {}),
      })
      rs.data.forEach(element => {
        statementlist.push({
          date: element.created_at ? moment(element.created_at).format('YYYY-MM-DD HH:mm') : '',
          member: element.member ? String(element.member.phone) : null,
          amount: element.amount,
          bonus: element.bonus,
          type: element.type,
          detail: element.detail,
          by: element.member ? String(element.member.phone) : null,
        })
      })
      let ws = XLSX.utils.json_to_sheet(statementlist);
      ws['!cols'] = wscols;
      const wb = { Sheets: { BonusReport: ws }, SheetNames: ["BonusReport"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      // add log admin export
      await this.addLogExportCSV()
      this.$swal.close()
    },
    async addLogExportCSV() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            exportPage: 'รายงานโบนัส',
            url: window.location.href,
            detail: 'รายงานการรับโบนัส',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogExportCSV', data)
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },

    onChangePartner($event) {
      if ($event !== 'ทั้งหมด') {
        let valArr = $event.split(':')
        this.searchData = valArr[0].replace(" ", "")
      } else {
        this.searchData = ''
      }
    },
    onChangeSearch($event) {
      this.valPartner = 'ทั้งหมด'
    },
    async loadEntries(val) {
      this.isLoadingPartner = true;
      await this.getPartner(1, 100, val)
      this.isLoadingPartner = false;

      // this.entries = await fakeServer(this.queryTerm || '')
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
<style scoped>
@import '../../styles/custom.css';
</style>
